import * as React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import Layout from "../layout"
import Container from "../components/atoms/Container"
import { repositoryConfigs } from "../utils/prismicPreviews"

const PreviewPage = ({ isPrismicPreview }) => {
  if (isPrismicPreview !== true) {
    return <Layout>
      <Container>
        <h3>Not a preview</h3>
      </Container>
    </Layout>
  }

  return (
    <Layout>
      <Container>
        <h3>Loading Preview...</h3>
      </Container>
    </Layout>
  )
}

export default withPrismicPreviewResolver(PreviewPage, repositoryConfigs)
